import './index.css';
import React, { useEffect, useState } from 'react';
import {
  Image,
  Form,
  Input,
  Upload,
  Button,
  DatePicker,
  Radio,
  Col,
  Row,
  Typography,
  RadioChangeEvent,
  Space,
  Card,
  Divider,
  Avatar,
  UploadProps,
  Flex,
  message,
  Spin,
  Checkbox,
  notification,
  Modal,
  UploadFile,
  Result
} from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import {
  deleteDocEmpowerChiefAccount,
  goNext,
  goPrev,
  goToStep,
  saveDraftCompanyEntity,
  setDocEmpowerChiefAccount,
  updateEmpowerChiefAccountantBackImage,
  updateEmpowerChiefAccountantFaceImage,
  updateEmpowerChiefAccountantFrontImage,

} from '../../onboarding.reducer';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { Company } from '@/shared/model/company.model';
import { translations } from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { deepMerge } from '@/shared/utils/object-utils';
import { useNavigate } from 'react-router-dom';
import { displayPartsToString } from 'typescript';
import * as yup from 'yup';
import { yupValidator } from '@/shared/utils/yup-utils';
import { completeOnboarding, executeOcrPersonEKycRepresentative, onboardingApi, uploadFileRegistration, useCompleteOnboardingMutation, useExecuteOcrPersonEKycRepresentativeMutation, useGetCompanyQuery, useUpdateCompanyMutation, useUploadFileRegistrationMutation, useUploadRepresentativeBackImageMutation, useUploadRepresentativeFaceImageMutation, useUploadRepresentativeFrontImageMutation, useVertifyIdentifyMutation } from '@/services/api/onboarding.api';
import { CompanyEmployee } from '@/shared/model/company-employee.model';
import { convertDateTimeToServer } from '@/shared/utils/date-utils';
import dayjs from "dayjs";
import { CloudDownloadOutlined, EyeTwoTone, UploadOutlined } from '@ant-design/icons';
import { extractName } from "@/shared/utils/jmix-rest/file";
import { userInfoApi } from '@/services/api/user-info.api';
import { CorporatePositionEnum, EDoctype } from '@/shared/model/enums/enums';
import { fileApi } from '@/services/api/file.api';


const { Title, Text, Link } = Typography;

const getDefaultImage = () => {
  return '/images/default_image.png';
};

function isValidDate(dateString: any): boolean {
  const now = new Date();
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!dateString) {
    return true
  }
  if (!dateRegex.test(dateString)) return false;

  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  if (date > now) {
    return false;
  }

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

const formatDate = (dateString: string): string => {
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString) || !dateString) {
    return dateString;
  }

  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};
const reFormatDate = (dateString: string): string => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString) || !dateString) {
    return dateString;
  }

  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
};

export default function OnboardingEmpowerChiefAccount(props: any) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { onNext, onPrev, onSaveDraft } = props;

  const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  const [canNext, setCanNext] = useState(false)
  const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
  const { empowerChiefAccountant } = companyEntity;
  const [form] = Form.useForm();
  const [completeOnboarding, { data: dataCompleted, isLoading: isLoadingCompleted, isSuccess: isSuccessCompleted, isError: isErrorCompleted }] = useCompleteOnboardingMutation()
  const [uploadFileRegistration, { data, isLoading, isSuccess, isError }] = useUploadFileRegistrationMutation()
  const [downloadfile, { data: pdfFlie, isLoading: isLoadingGetFile, isSuccess: isSuccessGetFlie, isError: isErrorGetFlie }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [downloadFileFrontImage, { data: frontImageFile, isLoading: isLoadingDownload, isError: isErrorDownload, isSuccess: succesDownloadFrontImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [downloadFileBackImage, { data: backImageFile, isSuccess: succesDownloadBackImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [downloadFileFaceImage, { data: faceImageFile, isSuccess: succesDownloadFaceImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()

  const [uploadRepresentativeFrontImage, { reset: resetFrontImage, data: frontImage, isLoading: isLoadingFrontImage, isSuccess: isSuccessFrontImage, isError: isErrorFrontImage }] = useUploadRepresentativeFrontImageMutation()
  // const [downloadRepresentativeFrontImage, { data: frontImageFile, isSuccess: succesDownloadFrontImageFile }] = onboardingApi.endpoints.downloadRepresentativeFrontImage.useLazyQuery();
  const [uploadRepresentativeBackImage, { reset: resetBackImage, data: backImage, isLoading: isLoadingBackImage, isSuccess: isSuccessBackImage, isError: isErrorBackImage }] = useUploadRepresentativeBackImageMutation()
  // const [downloadRepresentativeBackImage, { data: backImageFile, isSuccess: succesDownloadBackImageFile }] = onboardingApi.endpoints.downloadRepresentativeBackImage.useLazyQuery();
  const [uploadRepresentativeFaceImage, { reset: resetFaceImage, data: faceImage, isLoading: isLoadingFaceImage, isSuccess: isSuccessFaceImage, isError: isErrorFaceImage }] = useUploadRepresentativeFaceImageMutation()
  // const [downloadRepresentativeFaceImage, { data: faceImageFile, isSuccess: succesDownloadFaceImageFile }] = onboardingApi.endpoints.downloadRepresentativeFaceImage.useLazyQuery();
  const [executeOcrPersonEKycRepresentative, { data: dataOCR, isLoading: isLoadingOCR, isSuccess: isSuccessOCR, isError: isErrorOCR }] = useExecuteOcrPersonEKycRepresentativeMutation();
  const [vertifyIdentify, { data: vertifyIdentifyUser, isLoading: isLoadingVertify, isSuccess: isSuccessVertify, isError: isErrorVertify }] = useVertifyIdentifyMutation();
  const [extract, setExtract] = useState(false)
  const [count, setCount] = useState(true);
  const [docType, setDocType] = useState(companyEntity?.empowerChiefAccountant?.docType || "CMND");
  const [changeDocType, setChangeDocType] = useState(false)
  const [checkRespre, setCheckRespre] = useState<CompanyEmployee | null | undefined>(empowerChiefAccountant)
  const [firstSetRespre, setFirstSetRespre] = useState(true);
  const [localFrontImage, setLocalFrontImage] = useState<any>(null)
  const [localBackImage, setLocalBackImage] = useState<any>(null)
  const [localFaceImage, setLocalFaceImage] = useState<any>(null)
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [typeFileDelete, setTypeFileDelete] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false)

  const [openModalReviewFile, setOpenModalReviewFile] = useState(false)

  let schema = yup.object().shape({
    empowerChiefAccountant: yup.object().shape({
      name: yup.string().nullable().test('valid', 'Họ và tên không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      cardIssueDate: yup.string().nullable()
        .test('valid-date', 'Ngày cấp không hợp lệ.', value => {
          if (!value) return true;
          if (value.trim().length === 0) return false;
          if (dayjs(value).isAfter(dayjs())) return false;
          return true;
        }),
      birthday: yup.string().nullable()
        .test('valid-date', 'Ngày sinh không hợp lệ.', value => {
          if (!value) return true;
          if (value.trim().length === 0) return false;
          if (dayjs(value).isAfter(dayjs())) return false;
          return true;
        }),
      phoneNumber: yup.string().nullable().test('valid', 'Số điện thoại không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      cardID: yup.string().nullable().test('valid', 'Số giấy tờ định danh không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      cardIssueLocation: yup.string().nullable().test('valid', 'Nơi cấp không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      email: yup.string().email(t(translations.register.errors.emailInvalid)).nullable()
        .test('valid email', t(translations.register.errors.emailInvalid), (value: any) => {
          if (!value) return true;
          if (value.trim().length === 0) return false;
          return /^(?=.{6,30}$)(?!.*\.\.)(?!.*\.$)(?!^\.)[a-zA-Z0-9.]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/.test(value)
        }),
      cardFrontImage: yup.string().nullable(),
      cardBackImage: yup.string().nullable(),
      faceImage: yup.string().nullable(),
      empowerDateFrom: yup.string().nullable()
        .test('valid-date', 'Ngày bắt đầu ủy quyền đã hết hạn.', value => {
          return true;
        }),
      empowerDateTo: yup.string().nullable()
        .test('valid-date', 'Ngày kết thúc ủy quyền không được nhỏ hơn ngày hiện tại!', (value, context) => {
          const empowerDateTo = context.parent.empowerDateTo;
          const currentDate = dayjs();
          const empowerDateFromValue = dayjs(value);
          if (!value) return true;
          if (empowerDateFromValue.isSame(currentDate, 'day')) return true
          if (dayjs(value).isBefore(dayjs())) return false;
          return true;
        })
        .test('valid-date', 'Ngày kết thúc ủy quyền không được nhỏ hơn ngày bắt đầu ủy quyền!', (value, context) => {
          const empowerDateTo = context.parent.empowerDateTo;
          if (!value) return true;
          if ((empowerDateTo && (dayjs(value).isBefore(empowerDateTo)))) return false;
          return true;
        }),
    }),
  });
  const yupSync = yupValidator(schema, form.getFieldsValue);

  interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
  }

  const onCompleteOnboarding = () => {
    form.validateFields()
      .then((values: any) => {
        setOpenModal(true)
      })
      .catch((errors: any) => {
        console.error('Validation error:', errors);
      });
  }

  const uploadProps: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      //   setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                Bạn chỉ có thể tải định dạng PDF!
              </div>
            )
        });
        return
      }
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                File phải nhỏ hơn 20MB!
              </div>
            )
        });
        return
      }
      const formData = new FormData();
      formData.append("file", file);
      // dispatch(uploadFileRegistration(formData))
      uploadFileRegistration(formData);


      return
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: 'Download',
      showRemoveIcon: true,
      // removeIcon: <DeleteOutlined onClick={onDeleteFile} />,
    },
    fileList,
  };

  const uploadFrontImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();

      formData.append('file', file);
      // dispatch(uploadRepresentativeFrontImage(formData));
      uploadRepresentativeFrontImage(formData)
      return false;
    },
  };
  const uploadBackImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();
      formData.append('file', file);
      uploadRepresentativeBackImage(formData)
      return false;
    },
  };
  const uploadFaceImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();
      formData.append('file', file);
      uploadRepresentativeFaceImage(formData)
      return false;
    },
  };

  const onPreviewFile = async () => {
    if (companyEntity?.empowerChiefAccountant?.empowerDoc) {
      setOpenModalReviewFile(true)
      downloadfile({ fileId: companyEntity.empowerChiefAccountant?.empowerDoc, objectType: null })
    }

    else {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Tải File không thành công!
            </div>
          )
      })
    }
  }

  const onDeleteFile = () => {
    dispatch(deleteDocEmpowerChiefAccount())
  }

  // useEffect(() => {
  //   form.setFields([{
  //     name: "fileEmpower",
  //     errors: ["Giấy tờ ủy quyền có kí số không được để trống!"]
  //   }])
  // }, [])


  const onGoNext = () => {
    let uploadFile = false

    if (companyEntity.empowerChiefAccountant?.cardFrontImage ||
      companyEntity.empowerChiefAccountant?.cardBackImage ||
      companyEntity.empowerChiefAccountant?.faceImage) {
      if (!companyEntity.empowerChiefAccountant?.cardFrontImage) {
        form.setFields([{
          name: ['empowerChiefAccountant', 'cardFrontImage'],
          errors: ["Vui lòng tải đầy đủ ảnh!"]
        }])
        uploadFile = true
      }
      if (!companyEntity.empowerChiefAccountant?.cardBackImage && companyEntity.empowerChiefAccountant.docType === "CMND") {
        form.setFields([{
          name: ['empowerChiefAccountant', 'cardBackImage'],
          errors: ["Vui lòng tải đầy đủ ảnh!"]
        }])
        uploadFile = true
      }
      if (!companyEntity.empowerChiefAccountant?.faceImage) {
        form.setFields([{
          name: ['empowerChiefAccountant', 'faceImage'],
          errors: ["Vui lòng tải đầy đủ ảnh!"]
        }])
        uploadFile = true
      }
    }
    if (!uploadFile) {
      if (!companyEntity.empowerChiefAccountant?.empowerDoc && extract) {
        form.setFields([{
          name: "fileEmpower",
          errors: ["Giấy tờ ủy quyền có kí số không được để trống!"]
        }])
      }
      form
        .validateFields()
        .then(async values => {

          // if (extract === false) {
          //   notification.info({ message: 'Hãy trích xuất hình ảnh để tiếp tục!' })
          // }
          // else {



          //   // if((checkRespre?.name || checkRespre?.cardIssueDate) && (checkRespre.name === entityForm.empowerChiefAccountant.name && checkRespre.cardIssueDate === entityForm.empowerChiefAccountant.cardIssueDate)){

          //   // } 

          //   // else{
          //   //   notification.error({message:"Xác thực thông tin kế toán trưởng không thành công!", description:'Thông tin kế toán trưởng không trùng khớp với thông tin kế toán trưởng trong giấy đăng ký kinh doanh.'})
          //   // }
          // }

          if (companyEntity?.empowerChiefAccountant?.docType === EDoctype.CCCD && companyEntity?.empowerChiefAccountant?.cardFrontImage && companyEntity?.empowerChiefAccountant?.cardBackImage && companyEntity?.empowerChiefAccountant?.faceImage && !extract
            || companyEntity?.empowerChiefAccountant?.docType === EDoctype.HC && companyEntity?.empowerChiefAccountant?.cardFrontImage && companyEntity?.empowerChiefAccountant?.faceImage && !extract
          ) {
            notification.info({
              message:
                (
                  <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                    Hãy trích xuất hình ảnh để tiếp tục!
                  </div>
                )
            })
          }

          else {
            let date = form.getFieldValue(['empowerChiefAccountant', 'cardIssueDate'])
            let birthDay = form.getFieldValue(['empowerChiefAccountant', 'birthday'])
            let empowerDateTo = form.getFieldValue(['empowerChiefAccountant', 'empowerDateTo'])
            let empowerDateFrom = form.getFieldValue(['empowerChiefAccountant', 'empowerDateFrom'])

            let entityForm = form.getFieldsValue();

            entityForm = {
              ...entityForm,
              empowerChiefAccountant: {
                ...entityForm.empowerChiefAccountant,
                position: CorporatePositionEnum.AUTHORIZATION_CHIEF_ACCOUNTANT,
                cardIssueDate: date ? date.format("YYYY-MM-DD") : "",
                birthday: birthDay ? birthDay.format("YYYY-MM-DD") : "",
                empowerDateTo: empowerDateTo ? empowerDateTo.format("YYYY-MM-DD") : "",
                empowerDateFrom: empowerDateFrom ? empowerDateFrom.format("YYYY-MM-DD") : "",
              }
            }

            let empowerChiefAccountantOriginal = deepMerge(companyEntity.empowerChiefAccountant, entityForm.empowerChiefAccountant)
            await dispatch(saveDraftCompanyEntity({ ...companyEntity, empowerChiefAccountant: empowerChiefAccountantOriginal }));
            await dispatch(goNext({ ...companyEntity, empowerChiefAccountant: empowerChiefAccountantOriginal }));
          }

          // const entity = deepMerge(companyEntity, { empowerChiefAccountant: values });
        })
        .catch(errorInfo => {console.log(errorInfo)});
    }
  };

  const onGoPrev = () => {
    let date = form.getFieldValue(['empowerChiefAccountant', 'cardIssueDate'])
    let birthDay = form.getFieldValue(['empowerChiefAccountant', 'birthday'])
    let empowerDateTo = form.getFieldValue(['empowerChiefAccountant', 'empowerDateTo'])
    let empowerDateFrom = form.getFieldValue(['empowerChiefAccountant', 'empowerDateFrom'])
    let entityForm = form.getFieldsValue();

    entityForm = {
      ...entityForm,
      empowerChiefAccountant: {
        ...entityForm.empowerChiefAccountant,
        cardIssueDate: date ? date.format("YYYY-MM-DD") : null,
        birthday: birthDay ? birthDay.format("YYYY-MM-DD") : null,
        empowerDateTo: empowerDateTo ? empowerDateTo.format("YYYY-MM-DD") : null,
        empowerDateFrom: empowerDateFrom ? empowerDateFrom.format("YYYY-MM-DD") : null,
      }
    }

    let empowerChiefAccountantOriginal = deepMerge(companyEntity.empowerChiefAccountant, entityForm.empowerChiefAccountant)
    dispatch(saveDraftCompanyEntity({
      ...companyEntity,
      empowerChiefAccountant: {
        ...companyEntity.empowerChiefAccountant,
        ...empowerChiefAccountantOriginal
      }
    }));
    dispatch(goPrev({
      ...companyEntity,
      empowerChiefAccountant: {
        ...companyEntity.empowerChiefAccountant,
        ...empowerChiefAccountantOriginal
      }
    }));
  };
  const onClose = () => {
    //Check if editingMode = true
    dispatch(goToStep(1));

  };
  const onChangeDocType = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    setDocType(newValue);
    form.setFieldsValue({
      empowerChiefAccountant: {
        ...form.getFieldValue('empowerChiefAccountant'),
        docType: newValue,
      },
    });
    setChangeDocType(true)
  };

  const onFormFieldsChange = (allFields: FieldData[]) => {
    // let entityForm = form.getFieldsValue();
    // let empowerChiefAccountantOriginal = deepMerge(companyEntity.empowerChiefAccountant, entityForm.empowerChiefAccountant)
    // dispatch(saveDraftCompanyEntity({ ...companyEntity, ...{ empowerChiefAccountant: empowerChiefAccountantOriginal } }));
    // console.log("form fields updated")
  }
  const onGetOcrPersonEKyc = async () => {
    await vertifyIdentify({
      frontImage: companyEntity?.empowerChiefAccountant?.cardFrontImage,
      faceImage: companyEntity?.empowerChiefAccountant?.faceImage,
    });
  };

  const deleteFile = (values: any) => {
    let dataDeleteFile
    if (values === "front") {
      setLocalFrontImage(null)
      resetFrontImage()

      let deleteFile = {
        ...companyEntity.empowerChiefAccountant,
        cardFrontImage: null,
      }
      dataDeleteFile = deepMerge(companyEntity?.empowerChiefAccountant, deleteFile)

      form.setFieldValue(['empowerChiefAccountant', 'cardFrontImage'], null)
    }

    else if (values === "back") {
      setLocalBackImage(null)
      resetBackImage()

      let deleteFile = {
        ...companyEntity.empowerChiefAccountant,
        cardBackImage: null,
      }
      dataDeleteFile = deepMerge(companyEntity?.empowerChiefAccountant, deleteFile)

      form.setFieldValue(['empowerChiefAccountant', 'cardBackImage'], null)
    }

    else if (values === "face") {
      setLocalFaceImage(null)
      resetFaceImage()

      let deleteFile = {
        ...companyEntity.empowerChiefAccountant,
        faceImage: null
      }
      dataDeleteFile = deepMerge(companyEntity?.empowerChiefAccountant, deleteFile)

      form.setFieldValue(['empowerChiefAccountant', 'faceImage'], null)
    }

    setExtract(false)

    const fieldsToReset = [
      ['empowerChiefAccountant', 'email'], ['empowerChiefAccountant', 'phoneNumber'], ['empowerChiefAccountant', 'birthday'], ['empowerChiefAccountant', 'cardIssueLocation'], ['empowerChiefAccountant', 'cardIssueDate'], ['empowerChiefAccountant', 'cardID'], ['empowerChiefAccountant', 'name']
    ];

    fieldsToReset.forEach(field => form.setFieldValue(field, null));

    dispatch(saveDraftCompanyEntity({
      ...companyEntity,
      empowerChiefAccountant: {
        ...empowerChiefAccountant,
        ...dataDeleteFile,
        docType: docType
      }
    }));
  }

  useEffect(() => {
    form.setFields([{
      name: "fileEmpower",
      errors: []
    }]);
  }, [companyEntity?.empowerChiefAccountant?.empowerDoc])

  useEffect(() => {
    if (empowerChiefAccountant?.cardFrontImage) {
      form.setFieldValue(['empowerChiefAccountant', 'cardFrontImage'], empowerChiefAccountant?.cardFrontImage)
    }
    if (empowerChiefAccountant?.cardBackImage) {
      form.setFieldValue(['empowerChiefAccountant', 'cardBackImage'], empowerChiefAccountant?.cardBackImage)
    }
    if (empowerChiefAccountant?.faceImage) {
      form.setFieldValue(['empowerChiefAccountant', 'faceImage'], empowerChiefAccountant?.faceImage)
    }
  }, [empowerChiefAccountant])

  useEffect(() => {
    if (companyEntity.empowerChiefAccountant?.cardFrontImage) {
      form.setFields([{
        name: ['empowerChiefAccountant', 'cardFrontImage'],
        errors: []
      }])
    }
    if (companyEntity.empowerChiefAccountant?.cardBackImage) {
      form.setFields([{
        name: ['empowerChiefAccountant', 'cardBackImage'],
        errors: []
      }])
    }
    if (companyEntity.empowerChiefAccountant?.faceImage) {
      form.setFields([{
        name: ['empowerChiefAccountant', 'faceImage'],
        errors: []
      }])
    }
  }, [companyEntity.empowerChiefAccountant])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setDocEmpowerChiefAccount(data))
    }
  }, [isSuccess, isLoading, isError, data])

  useEffect(() => {
    if (changeDocType) {
      setLocalFrontImage(null)
      setLocalBackImage(null)
      setLocalFaceImage(null)
      resetFrontImage()
      resetBackImage()
      resetFaceImage()

      form.resetFields();

      let deleteFile = {
        ...companyEntity.empowerChiefAccountant,
        cardFrontImage: null,
        cardBackImage: null,
        faceImage: null
      }
      let dataDeleteFile = deepMerge(companyEntity?.empowerChiefAccountant, deleteFile)
      dispatch(saveDraftCompanyEntity({
        ...companyEntity,
        empowerChiefAccountant: {
          ...empowerChiefAccountant,
          ...dataDeleteFile,
          docType: docType
        }
      }));

      setExtract(false)

      form.resetFields();

      const fieldsToReset = [
        ['empowerChiefAccountant', 'email'], ['empowerChiefAccountant', 'phoneNumber'], ['empowerChiefAccountant', 'birthday'], ['empowerChiefAccountant', 'cardIssueLocation'], ['empowerChiefAccountant', 'cardIssueDate'], ['empowerChiefAccountant', 'cardID'], ['empowerChiefAccountant', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));

      form.setFieldsValue({
        empowerChiefAccountant: {
          docType: docType,
          position: "Ủy quyền kế toán trưởng",
        },
      });

      setChangeDocType(false)
    }
  }, [docType])

  useEffect(() => {
    if (frontImageFile && succesDownloadFrontImageFile && localFrontImage !== frontImageFile) {
      setLocalFrontImage(frontImageFile);
    }
  }, [frontImageFile, succesDownloadFrontImageFile])

  useEffect(() => {
    if (backImageFile && succesDownloadBackImageFile && localBackImage !== backImageFile) setLocalBackImage(backImageFile);

  }, [backImageFile, succesDownloadBackImageFile])

  useEffect(() => {
    if (faceImageFile && succesDownloadFaceImageFile && localFaceImage !== frontImageFile) setLocalFaceImage(faceImageFile);

  }, [faceImageFile, succesDownloadFaceImageFile]);

  useEffect(() => {
    setCount(false)


    if (isSuccessVertify && vertifyIdentifyUser && !count && companyEntity.empowerChiefAccountant) {
      executeOcrPersonEKycRepresentative(companyEntity.empowerChiefAccountant);
    }

    if (isSuccessVertify && !vertifyIdentifyUser && !count) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              eKYC cá nhân không khớp với ảnh trên giấy tờ
            </div>
          )
      })
      setExtract(false)
    }

    if (isErrorVertify && !count) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Không thể thực hiện nhận dạng!
            </div>
          )
      })
      setExtract(false)
    }

  }, [vertifyIdentifyUser, isLoadingVertify, isSuccessVertify, isErrorVertify])

  useEffect(() => {
    if (!!empowerChiefAccountant?.cardFrontImage) {
      downloadFileFrontImage({ fileId: empowerChiefAccountant.cardFrontImage, objectType: null })
    }
  }, [empowerChiefAccountant?.cardFrontImage]);
  useEffect(() => {
    if (!!empowerChiefAccountant?.cardBackImage)
      downloadFileBackImage({ fileId: empowerChiefAccountant.cardBackImage, objectType: null });
  }, [empowerChiefAccountant?.cardBackImage]);
  useEffect(() => {
    if (!!empowerChiefAccountant?.faceImage)
      downloadFileFaceImage({ fileId: empowerChiefAccountant.faceImage, objectType: null });
  }, [empowerChiefAccountant?.faceImage]);

  useEffect(() => {
    if (isSuccessFrontImage && !!frontImage?.fileRef) {
      setExtract(false)

      const fieldsToReset = [
        ['empowerChiefAccountant', 'email'], ['empowerChiefAccountant', 'phoneNumber'], ['empowerChiefAccountant', 'birthday'], ['empowerChiefAccountant', 'cardIssueLocation'], ['empowerChiefAccountant', 'cardIssueDate'], ['empowerChiefAccountant', 'cardID'], ['empowerChiefAccountant', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      //update FrontImage
      dispatch(updateEmpowerChiefAccountantFrontImage(frontImage))
    }
  }, [isSuccessFrontImage])
  useEffect(() => {
    if (isSuccessBackImage && !!backImage?.fileRef) {
      setExtract(false)

      const fieldsToReset = [
        ['empowerChiefAccountant', 'email'], ['empowerChiefAccountant', 'phoneNumber'], ['empowerChiefAccountant', 'birthday'], ['empowerChiefAccountant', 'cardIssueLocation'], ['empowerChiefAccountant', 'cardIssueDate'], ['empowerChiefAccountant', 'cardID'], ['empowerChiefAccountant', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      //update BackImage
      dispatch(updateEmpowerChiefAccountantBackImage(backImage))
    }
  }, [isSuccessBackImage])
  useEffect(() => {
    if (isSuccessFaceImage && !!faceImage?.fileRef) {
      setExtract(false)

      const fieldsToReset = [
        ['empowerChiefAccountant', 'email'], ['empowerChiefAccountant', 'phoneNumber'], ['empowerChiefAccountant', 'birthday'], ['empowerChiefAccountant', 'cardIssueLocation'], ['empowerChiefAccountant', 'cardIssueDate'], ['empowerChiefAccountant', 'cardID'], ['empowerChiefAccountant', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      //update FaceImage
      dispatch(updateEmpowerChiefAccountantFaceImage(faceImage))
    }
  }, [isSuccessFaceImage])

  // useEffect(() => {

  // }, [companyEntity])

  useEffect(() => {
    if (dataOCR && dataOCR.cardIssueDate) {
      if (dataOCR && dataOCR.cardIssueDate) {
        let dataOcrNew

        if (dataOCR.docType === null) {
          let dataOcrNew = {
            ...dataOCR,
            docType: docType
          }
        }
        else {
          dataOcrNew = dataOCR
        }
        dispatch(saveDraftCompanyEntity({ ...companyEntity, empowerChiefAccountant: dataOcrNew }))
        form.setFieldsValue({
          empowerChiefAccountant: {
            ...dataOcrNew,
            position: "Ủy quyền kế toán trưởng",
            birthday: dataOcrNew?.birthday ? dayjs(dataOcrNew?.birthday, "YYYY-MM-DD") : "",
            cardIssueDate: dataOcrNew?.cardIssueDate ? dayjs(dataOcrNew?.cardIssueDate, "YYYY-MM-DD") : ""
          }
        });

        notification.success({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                Trích xuất thông tin thành công!
              </div>
            )
        });
        setExtract(true);
      }
    }

    if (dataOCR === null || (dataOCR?.name === null && dataOCR?.cardIssueDate === null)) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Trích xuất thông tin không thành công!
            </div>
          )
      });
      setExtract(false)
    }
  }, [dataOCR])

  //cập nhật form lần đầu
  useEffect(() => {
    let entity = deepMerge(companyEntity)
    entity = {
      ...entity,
      empowerChiefAccountant: {
        ...entity.empowerChiefAccountant,
        birthday: entity.empowerChiefAccountant.birthday ? dayjs(entity.empowerChiefAccountant.birthday, "YYYY-MM-DD") : "",
        cardIssueDate: entity.empowerChiefAccountant.cardIssueDate ? dayjs(entity.empowerChiefAccountant.cardIssueDate) : "",
        empowerDateFrom: entity.empowerChiefAccountant.empowerDateFrom ? dayjs(entity.empowerChiefAccountant.empowerDateFrom) : "",
        empowerDateTo: entity.empowerChiefAccountant.empowerDateTo ? dayjs(entity.empowerChiefAccountant.empowerDateTo) : ""
      }
    }
    form.setFieldsValue(entity)

    form.setFieldsValue({
      empowerChiefAccountant: {
        position: "Ủy quyền kế toán trưởng",
      },
    });

    if (!companyEntity.empowerChiefAccountant?.docType) {
      dispatch(saveDraftCompanyEntity({
        ...companyEntity,
        empowerChiefAccountant: {
          ...empowerChiefAccountant,
          docType: "CMND"
        }
      }));
      form.setFieldsValue({
        empowerChiefAccountant: {
          docType: "CMND",
        },
      });
    }

    if (!!empowerChiefAccountant?.cardFrontImage) downloadFileFrontImage({ fileId: empowerChiefAccountant.cardFrontImage, objectType: null })
    if (!!empowerChiefAccountant?.cardBackImage) downloadFileBackImage({ fileId: empowerChiefAccountant.cardBackImage, objectType: null });
    if (!!empowerChiefAccountant?.faceImage) downloadFileFaceImage({ fileId: empowerChiefAccountant.faceImage, objectType: null });
    // if (localFrontImage || localBackImage || localFaceImage) {
    //   form.setFieldsValue({
    //     empowerChiefAccountant: {
    //       cardFrontImage: localFrontImage,
    //       cardBackImage: localBackImage,
    //       faceImage: localFaceImage,
    //     },
    //   });
    // }

    if (
      (empowerChiefAccountant?.docType?.toString() === "CMND" && empowerChiefAccountant?.cardBackImage !== null && empowerChiefAccountant?.cardFrontImage != null && empowerChiefAccountant?.faceImage !== null && empowerChiefAccountant?.name !== null && empowerChiefAccountant?.cardIssueDate !== null) ||
      (empowerChiefAccountant?.docType?.toString() === "HC" && empowerChiefAccountant?.cardFrontImage != null && empowerChiefAccountant?.faceImage !== null && empowerChiefAccountant?.name !== null && empowerChiefAccountant?.cardIssueDate !== null)
    ) {
      setExtract(true)
    }

  }, [])

  useEffect(() => {
    if (isErrorCompleted) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Đăng ký thông tin doanh nghiệp không thành công!
            </div>
          )
      })
    }

    // if (isLoadingCompleted) {
    //   notification.info({ message: "Đang lưu thông tin doanh nghiệp..." })
    // }

    if (isSuccessCompleted) {
      notification.success({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Đăng ký thông tin doanh nghiệp thành công!
            </div>
          )
      })
      dispatch(saveDraftCompanyEntity(dataCompleted));
    }
  }, [isSuccessCompleted, isErrorCompleted, isLoadingCompleted])

  return (
    <Spin spinning={isLoadingOCR || isLoadingVertify} >
      <Content className='empowerChiefAccount'>
        <Card type='inner' title='Thông tin ủy quyền kế toán trưởng' style={{ margin: 20 }} >
          <Row>
            <Form form={form} initialValues={{ empowerChiefAccountant: { docType } }}>
              <Form.Item label={"Loại giấy tờ"} name={['empowerChiefAccountant', 'docType']}>
                <Radio.Group onChange={onChangeDocType} value={companyEntity?.empowerChiefAccountant?.docType?.toString()} defaultValue={'CMND'}>
                  <Radio value={"CMND"} >CMND/CCCD/CCCD gắn chíp</Radio>
                  <Radio value={"HC"}>Hộ chiếu</Radio>
                </Radio.Group>
              </Form.Item>
              <Divider orientation="right" />
            </Form>
          </Row>
          <Form colon={false} id='orcForm'
            form={form}
          >
            {/* <Form.Item name={['empowerChiefAccountant', 'hasEKYC']} >

            <Input />
          </Form.Item> */}

          </Form>
          <Form id='imagesForm'
            form={form}
          >
            <Row style={{ margin: '24px 0px' }}>
              <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                <div
                  style={{
                    padding: '12px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px 8px 8px 8px',
                    minHeight: 200,
                    marginRight: 10
                  }}
                >
                  <Form.Item
                    name={['empowerChiefAccountant', 'cardFrontImage']}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                      <Image
                        style={{ width: '100%', maxHeight: '18vw' }}
                        preview={false}
                        src={
                          !!localFrontImage
                            ? localFrontImage
                            : getDefaultImage()
                        }
                        fallback={getDefaultImage()}
                      />
                    </div>
                  </Form.Item>

                  <h5>Ảnh mặt trước giấy tờ</h5>
                  <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                  <Row justify={'space-between'}>
                    <Col lg={{ span: 11 }}>
                      <Upload {...uploadFrontImageProps} maxCount={1} showUploadList={false}>
                        <Button disabled={isLoadingOCR || isLoadingVertify}
                          style={{
                            width: '100%',
                            border: 'none',
                            backgroundColor: 'rgba(232, 241, 254, 1)',
                            color: 'rgba(24, 119, 242, 1)',
                            height: 40
                          }}
                        >
                          Tải ảnh lên
                        </Button>
                      </Upload>
                    </Col>
                    <Col lg={{ span: 11 }}>
                      <Button disabled={!empowerChiefAccountant?.cardFrontImage} onClick={() => {
                        setConfirmDeleteFile(true)
                        setTypeFileDelete("front")
                      }} style={{ width: '100%', height: 40 }}>
                        Xóa
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>

              {!(docType === "HC") && (
                <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                  <div
                    style={{
                      padding: '12px',
                      border: '1px solid #f0f0f0',
                      borderRadius: '8px 8px 8px 8px',
                      minHeight: 200,
                      marginRight: 10
                    }}
                  >
                    <Form.Item
                      name={['empowerChiefAccountant', 'cardBackImage']}

                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}

                    >
                      <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                        <Image
                          style={{ width: '100%', maxHeight: '18vw' }}
                          preview={false}
                          src={
                            !!localBackImage
                              ? localBackImage
                              : getDefaultImage()
                          }
                          fallback={getDefaultImage()}
                        />
                      </div>
                    </Form.Item>

                    <h5>Ảnh mặt sau giấy tờ</h5>
                    <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                    <Row justify={'space-between'}>
                      <Col lg={{ span: 11 }}>
                        <Upload {...uploadBackImageProps} maxCount={1} showUploadList={false}>
                          <Button disabled={isLoadingOCR || isLoadingVertify}
                            style={{
                              width: '100%',
                              border: 'none',
                              backgroundColor: 'rgba(232, 241, 254, 1)',
                              color: 'rgba(24, 119, 242, 1)',
                              height: 40
                            }}
                          >
                            Tải ảnh lên
                          </Button>
                        </Upload>
                      </Col>
                      <Col lg={{ span: 11 }}>
                        <Button disabled={!empowerChiefAccountant?.cardBackImage} onClick={() => {
                          setConfirmDeleteFile(true)
                          setTypeFileDelete("back")
                        }} style={{ width: '100%', height: 40 }}>
                          Xóa
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}

              <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                <div
                  style={{
                    padding: '12px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px 8px 8px 8px',
                    minHeight: 200
                  }}
                >
                  <Form.Item
                    name={['empowerChiefAccountant', 'faceImage']}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}

                  >
                    <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                      <Image
                        style={{ width: '100%', maxHeight: '18vw' }}
                        preview={false}
                        src={
                          !!localFaceImage
                            ? localFaceImage
                            : getDefaultImage()
                        }
                        fallback={getDefaultImage()}
                      />
                    </div>
                  </Form.Item>
                  <h5>eKYC cá nhân</h5>
                  <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                  <Row justify={'space-between'}>
                    <Col lg={{ span: 11 }}>
                      <Upload {...uploadFaceImageProps} maxCount={1} showUploadList={false}>
                        <Button disabled={isLoadingOCR || isLoadingVertify}
                          style={{
                            width: '100%',
                            border: 'none',
                            backgroundColor: 'rgba(232, 241, 254, 1)',
                            color: 'rgba(24, 119, 242, 1)',
                            height: 40
                          }}
                        >
                          Tải ảnh lên
                        </Button>
                      </Upload>
                    </Col>
                    <Col lg={{ span: 11 }}>
                      <Button disabled={!empowerChiefAccountant?.faceImage} onClick={() => {
                        setConfirmDeleteFile(true)
                        setTypeFileDelete("face")
                      }} style={{ width: '100%', height: 40 }}>
                        Xóa
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>

          {((docType === "CMND" && !!empowerChiefAccountant?.cardFrontImage &&
            !!empowerChiefAccountant?.cardBackImage &&
            !!empowerChiefAccountant?.faceImage) ||
            (docType === "HC" && !!empowerChiefAccountant?.cardFrontImage &&
              !!empowerChiefAccountant?.faceImage)) && (
              <div>
                <Row justify={'center'}>
                  <Col lg={{ span: 12 }}>
                    {/* <Row justify={'end'}>
                      {(isLoadingOCR || isLoadingVertify) && <Spin>Đang tải thông tin</Spin>}
                    </Row> */}
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Row style={{ width: '100%' }} justify={'end'}>
                      <Button disabled={isLoadingOCR || isLoadingVertify} onClick={onGetOcrPersonEKyc} style={{ backgroundColor: '#1c00c2', color: 'white', height: 40, border: "none", float: 'right' }}><CloudDownloadOutlined /> Trích xuất</Button>
                    </Row>
                  </Col>
                </Row>
              </div>

            )}
        </Card >


        {/* <Divider /> */}
        < Card type='inner' title='Xác thực thông tin người được ủy quyền kế toán trưởng' style={{ margin: 20 }
        } >

          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Form
                form={form}
                onFieldsChange={(_, allFields) => {
                  onFormFieldsChange(allFields);
                }}
                labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                layout="horizontal"
                disabled={!extract ? true : false}
                requiredMark={false}
                colon={false}
              >
                <Form.Item
                  name={['empowerChiefAccountant', 'name']}
                  // name={'name'}
                  label={
                    <span>
                      Họ và tên
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Họ và tên không được để trống" }]}

                >
                  <Input placeholder="Nhập họ và tên" style={{ height: 40 }} maxLength={50} />
                </Form.Item>
                <Form.Item
                  name={['empowerChiefAccountant', 'cardID']}
                  // name={'name'}
                  label={
                    <span>
                      Số giấy tờ định danh
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Số giấy tờ định danh không được để trống" }]}

                >
                  <Input placeholder="Nhập số giấy tờ kinh doanh" style={{ height: 40 }} maxLength={12} />
                </Form.Item>

                <Form.Item
                  name={['empowerChiefAccountant', 'cardIssueDate']}
                  label={
                    <span>
                      Ngày cấp
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Ngày cấp không được để trống" }]}
                  style={{ width: '100%' }}
                >
                  <DatePicker placeholder='Nhập ngày cấp' style={{ height: 40, width: '100%' }} format={"DD/MM/YYYY"} />
                </Form.Item>

                <Form.Item
                  name={['empowerChiefAccountant', 'cardIssueLocation']}
                  // name={'name'}
                  label={
                    <span>
                      Nơi cấp
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Nơi cấp không được để trống" }]}

                >
                  <Input placeholder="Nhập nơi cấp" style={{ height: 40 }} maxLength={300} />
                </Form.Item>
              </Form>
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Form
                form={form}
                onFieldsChange={(_, allFields) => {
                  onFormFieldsChange(allFields);
                }}
                labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                layout="horizontal"
                disabled={!extract ? true : false}
                requiredMark={false}
                colon={false}
              >
                <Form.Item
                  name={['empowerChiefAccountant', 'birthday']}
                  label={
                    <span>
                      Ngày sinh
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract ? true : false, message: "Ngày sinh không được để trống" }]}
                  style={{ width: '100%' }}
                >
                  <DatePicker placeholder='Nhập ngày sinh' style={{ height: 40, width: '100%' }} format={"DD/MM/YYYY"} />
                </Form.Item>

                <Form.Item
                  label="Chức vụ"
                  labelAlign="left"
                  style={{ width: '100%' }}
                  name={['empowerChiefAccountant', 'position']}
                >
                  <Input disabled style={{ height: 40 }} />
                </Form.Item>

                <Form.Item
                  name={['empowerChiefAccountant', 'phoneNumber']}
                  label={
                    <span>
                      Số điện thoại
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract ? true : false, message: "Số điện thoại không được để trống" }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder='Nhập số điện thoại' style={{ height: 40 }} maxLength={10} />
                </Form.Item>

                <Form.Item
                  name={['empowerChiefAccountant', 'email']}
                  label={
                    <span>
                      Email
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract ? true : false, message: "Email không được để trống" }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder='Nhập email' style={{ height: 40 }} maxLength={50} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </ Card>

        < Card type='inner' title='Xác thực thông tin ủy quyền' style={{ margin: 20 }
        } >
          <Col
            xl={{ span: 12 }}
            lg={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Form
              form={form}
              onFieldsChange={(_, allFields) => {
                onFormFieldsChange(allFields);
              }}
              labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
              wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
              layout="horizontal"
              disabled={!extract ? true : false}
              colon={false}
            >
              <Form.Item
                // name={['empowerChiefAccountant', 'cardIssueLocation']}
                name={'fileEmpower'}
                label={
                  <span>
                    Giấy tờ ủy quyền có kí số
                    {extract && <span style={{ color: 'red' }}> *</span>}
                  </span>
                }
                labelAlign="left"

              // rules={[yupSync]}
              >
                {
                  companyEntity.empowerChiefAccountant?.empowerDoc ? <div style={{ display: "flex", width: '100%' }}>
                    <Button onClick={onPreviewFile} type='default' style={{ marginRight: 5, backgroundColor: 'rgb(232, 241, 254)', color: "rgb(24, 119, 242)", border: 'none' }}>{extractName(companyEntity.empowerChiefAccountant?.empowerDoc)}<EyeTwoTone /></Button>
                    <Button onClick={onDeleteFile}>{t(translations.action.delete)}</Button>
                  </div>
                    :
                    <Upload {...uploadProps}>
                      <Button icon={<UploadOutlined />}>Tải lên giấy ủy quyền</Button>
                    </Upload>
                }
              </Form.Item>
              <Form.Item
                name={['empowerChiefAccountant', 'empowerDateFrom']}
                label={
                  <span>
                    Ngày bắt đầu ủy quyền
                    {extract && <span style={{ color: 'red' }}> *</span>}
                  </span>
                }
                labelAlign="left"
                rules={[yupSync]}
                style={{ width: '100%' }}
              >
                <DatePicker placeholder='Nhập ngày bắt đầu ủy quyền' style={{ height: 40, width: '100%' }} format={"DD/MM/YYYY"} />
              </Form.Item>

              <Form.Item
                name={['empowerChiefAccountant', 'empowerDateTo']}
                label={
                  <span>
                    Ngày kết thúc ủy quyền
                    {extract && <span style={{ color: 'red' }}> *</span>}
                  </span>
                }
                labelAlign="left"
                rules={[yupSync]}
                style={{ width: '100%' }}
              >
                <DatePicker placeholder='Nhập ngày kết thúc ủy quyền' style={{ height: 40, width: '100%' }} format={"DD/MM/YYYY"} />
              </Form.Item>
            </Form>
          </Col>

        </ Card>

      </Content >
      <Footer
        style={{
          background: '#fff',
          // position: 'fixed',
          bottom: 0,
          width: '100%',
          boxShadow: '0px -1px 6px 0px rgba(45, 50, 57, 0.06)',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'inline-block' }}>
          {/* <Button disabled={isLoadingOCR} style={{ margin: '0 8px', height: 40 }} onClick={onSaveEntity}>
            {t(translations.action.save_draft)}
          </Button> */}
          <Button disabled={isLoadingOCR || isLoadingVertify} style={{ margin: '0 8px', height: 40 }} onClick={onGoPrev}>
            {t(translations.action.previous)}
          </Button>
        </div>
        <div style={{ display: 'inline-block' }}>

          <Button type="primary" style={{ margin: '0 8px', height: 40 }} onClick={onGoNext} disabled={isLoadingOCR || isLoadingVertify}>
            {t(translations.action.next)}
          </Button>
        </div>
      </Footer>

      <Modal
        open={confirmDeleteFile}
        onCancel={() => setConfirmDeleteFile(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Row gutter={[16, 16]}>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%', height: 40 }} key="console" onClick={() => {
                  setConfirmDeleteFile(false)
                }}>
                  Hủy
                </Button>
              </Col>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%', height: 40, backgroundColor: '#ED5E68' }} type="primary" key="console" onClick={() => {
                  deleteFile(typeFileDelete)
                  setConfirmDeleteFile(false)
                }}>
                  Xóa
                </Button>
              </Col>

            </Row>
          </>
        )}
      >
        {/* <Result
          title="Xác nhận xóa file"
          extra={
            <Row gutter={[16, 16]}>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%' }} key="console" onClick={() => {
                  setConfirmDeleteFile(false)
                }}>
                  Hủy
                </Button>
              </Col>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%' }} type="primary" key="console" onClick={() => {
                  deleteFile(typeFileDelete)
                  setConfirmDeleteFile(false)
                }}>
                  Xác nhận
                </Button>
              </Col>

            </Row>
          }
        /> */}
        <p style={{ margin: '24px 0', textAlign: 'center' }}>Bạn có chắc chắn muốn xóa ảnh</p>
      </Modal>

      <Modal
        className='chief-accountant'
        style={{ top: 200, textAlign: 'center' }}
        open={openModal}
        // open={true}
        title="Xác nhận"
        width={450}
        onCancel={() => {
          if (!disableButton) {
            setOpenModal(false)
          }
        }}
        footer={() => (
          <>
            <Row justify={'space-around'}>
              <Col span={11}>
                <Button style={{ width: '100%', height: 40 }} disabled={disableButton || isLoadingOCR} onClick={() => {
                  setOpenModal(false)
                }}>Quay lại</Button>
              </Col>
              <Col span={11}>
                <Button style={{ width: '100%', height: 40 }} disabled={disableButton || isLoadingOCR} type='primary' onClick={async () => {
                  // if (extract === false && !!!companyEntity?.empowerChiefAccountant?.name && !!!companyEntity?.empowerChiefAccountant?.cardIssueDate) {
                  //   notification.info({ message: 'Hãy trích xuất ảnh để tiếp tục' })
                  // }
                  // else {

                  // }
                  let name = form.getFieldValue(['empowerChiefAccountant', 'name'])
                  let date = form.getFieldValue(['empowerChiefAccountant', 'cardIssueDate'])
                  if (date) {
                    date = reFormatDate(date)
                  }
                  else {
                    date = ""
                  }
                  let entityForm = form.getFieldsValue();

                  entityForm = {
                    ...entityForm,
                    empowerChiefAccountant: {
                      ...entityForm.empowerChiefAccountant,
                      cardIssueDate: date
                    }
                  }
                  let empowerChiefAccountantOriginal = deepMerge(companyEntity.empowerChiefAccountant, entityForm.empowerChiefAccountant)

                  await dispatch(saveDraftCompanyEntity({ ...companyEntity, empowerChiefAccountant: empowerChiefAccountantOriginal }));

                  setDisableButton(true)

                  let entitySubmit;
                  entitySubmit = {
                    ...companyEntity,
                    empowerChiefAccountant: {
                      ...companyEntity.empowerChiefAccountant,
                      name: name,
                      cardIssueDate: date
                    }
                  }

                  await completeOnboarding(entitySubmit);
                  setOpenModal(false)
                  setDisableButton(false)
                }}>Xác nhận</Button>
              </Col>
            </Row>
            {isLoadingCompleted && (
              <Row justify={'center'} style={{ marginTop: 20 }}>
                <Spin>Đang đăng ký</Spin>
              </Row>
            )}


            {/* <CancelBtn />
            <OkBtn /> */}
          </>
        )}
      >
        <p style={{ margin: '24px 0' }}>Quý khách có chắc chắn muốn đăng ký thông tin doanh nghiệp không?</p>

      </Modal>

      <Modal
        open={openModalReviewFile}
        onOk={() => setOpenModalReviewFile(false)}
        onCancel={() => setOpenModalReviewFile(false)}
        title={"Xem chi tiết"}
        footer={(_, { OkBtn }) => (
          <>
            <Button onClick={() => setOpenModalReviewFile(false)}>Đóng</Button>
          </>
        )}
        width={'50vw'}
        centered={true}
      >
        {pdfFlie && (
          <iframe
            src={pdfFlie}
            width="100%"
            height="700px"
            title="PDF Viewer"
            frameBorder="0"
          />
        )}
      </Modal>

    </Spin>
  );
}
