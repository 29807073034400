import React, { useEffect, useState } from 'react';
import { Steps, Button, Layout, Col, Row, Table, Form, Input, Card, Spin, Modal, Radio, DatePicker, notification, Select, InputNumber } from 'antd';
import { UploadOutlined, BellOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import './index.css'
import { useTranslation } from 'react-i18next';
import { translations } from '@/i18n/i18n';
import { useNavigate } from 'react-router-dom';
import type { TableColumnsType } from 'antd';
import { UserCompany } from '@/shared/model/user-company.model';
import { useGetCurrentUserCompanyQuery, useUpdateUserInfoMutation } from '@/services/api/user-info.api';
import { useForm } from 'antd/es/form/Form';
import { goNext, goPrev, goToStep, saveDraftCompanyEntity, updateSuperAdmin } from '../../onboarding.reducer';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { Company } from '@/shared/model/company.model';
import type { DatePickerProps } from 'antd';
import dayjs from 'dayjs';

const { Option } = Select;

export default function OnboardingUserCompany(props: any) {
    const navigate = useNavigate();
    const { onNext, onPrev, onSaveDraft } = props
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
    // const {supperAdmin} = companyEntity
    const { data: useInfo, isLoading: isLoadGet, isError: isErrorGet, isSuccess: isSuccessGet, refetch } = useGetCurrentUserCompanyQuery();// useGetUserInfoQuery()
    const [updateUserInfo, { data: dataUpdateUserInfo, isError: isErrorUpdate, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate }] = useUpdateUserInfoMutation()

    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState<UserCompany[]>([]);

    const [openModalEdit, setOpenModalEdit] = useState(false)
    const [dataModal, setDataModal] = useState<UserCompany>()
    const [dateString, setDateString] = useState<String | String[]>()

    const onChangeDate: DatePickerProps['onChange'] = (_, dateString) => {
        if (typeof (dateString) === "string") {
            setDateString(dayjs(dateString, "DD/MM/YYYY").format("YYYY-MM-DD"));
            console.log(dateString)

        }
    };

    const onGoNext = () => {
        form
            .validateFields()
            .then(values => {
                if (!useInfo?.identificationNum || !useInfo?.position || !useInfo?.issuedBy || !useInfo?.dateRange) {
                    notification.error({
                        message:
                            (
                                <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                    Vui lòng nhập đầy đủ trường của admin để tiếp tục
                                </div>
                            )
                    })
                    return;
                }
                console.log(companyEntity)
                dispatch(goNext(companyEntity))
            })
            .catch(errorInfo => {
                notification.error({
                    message:
                        (
                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                Vui lòng nhập đầy đủ trường của admin để tiếp tục
                            </div>
                        )
                })
            });
    };

    const onGoPrev = () => {
        dispatch(goPrev(companyEntity));
    };
    const onClose = () => {
        //Check if editingMode = true
        dispatch(goToStep(1));

    };

    const pagination = {
        pageSize: 15,
    };

    const handleEdit = (record: UserCompany) => {
        console.log("Edit record", record);
        setOpenModalEdit(true)

        if (typeof (record.dateRange) === "string" && record.dateRange) {
            const [year, month, day] = record.dateRange.split("-").map(Number);

            record = {
                ...record,
                dateRange: dayjs(new Date(year, month - 1, day))
            }
        }

        setDataModal(record)
    };

    const columns: TableColumnsType<UserCompany> = [
        {
            title: "Số thứ tự",
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Tên hiển thị',
            dataIndex: 'fullName',
            key: 'fullName',
            fixed: 'left',
        },
        {
            title: 'Chức vụ', dataIndex: 'position', key: 'position',
            render: (position) => {
                if (position === "MANAGER") {
                    return "Trưởng phòng";
                }
                if (position === "SUB_MANAGER") {
                    return "Phó phòng";
                }
                if (position === "STAFF") {
                    return "Nhân viên";
                }
                if (position === "LEGAL_REPRESENTATIVE") {
                    return "Người đại diện pháp luật";
                }
                if (position === "CHIEF_ACCOUNTANT") {
                    return "Kế toán trưởng";
                }
                if (position === "AUTHORIZATION_OF_LEGAL_REPRESENTATIVE") {
                    return "Ủy quyền người đại diện pháp luật";
                }
                if (position === "AUTHORIZATION_OF_CHIEF_ACCOUNTANT") {
                    return "Ủy quyền kế toán trưởng";
                }
                return position;
            }
        },
        {
            title: 'Nhóm người dùng', dataIndex: 'roleAssignmentText', key: 'roleAssignmentText',
            render(value, record, index) {
                return "Admin"
            },
        },
        {
            title: 'Phân quyền ứng dụng',
            render(value, record, index) {
                return "Admin"
            }
        },
        {
            title: 'Giấy tờ định danh', dataIndex: 'docType', key: 'docType',
            render(docType) {
                if (docType === "CMND") {
                    return "CMND/CCCD/CCCD gắn chíp"
                }
                else if (docType === "HC") {
                    return "Hộ chiếu"
                }
                else {
                    return "Khác"
                }
            }
        },
        {
            title: 'Số giấy tờ định danh',
            dataIndex: 'identificationNum',
            key: 'identificationNum',
            fixed: 'left',
        },
        {
            title: 'Ngày cấp', dataIndex: 'dateRange', key: 'dateRange',
            render(value, record, index) {
                if (typeof (value) === "string" && value) {
                    const [year, month, day] = value.split("-").map(Number);

                    return dayjs(new Date(year, month - 1, day)).format("DD/MM/YYYY");
                }
            }
        },
        { title: 'Nơi cấp', dataIndex: 'issuedBy', key: 'issuedBy' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Số điện thoại', dataIndex: 'phoneNumber', key: 'phoneNumber' },
        // { title: 'Nhóm người dùng', dataIndex: 'address', key: '8' },
        {
            title: 'Tác vụ',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (_, record) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    {/* <Button type="primary"><PlusOutlined /></Button> */}
                    <Button type="primary" onClick={() => handleEdit(record)}><EditOutlined /> </Button>
                    {/* <Button><DeleteOutlined /> </Button> */}
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (useInfo) {
            setDataSource(prevDataSource => {
                const existingIndex = prevDataSource.findIndex(item => item.id === useInfo.id);

                if (existingIndex !== -1) {
                    return prevDataSource.map(item =>
                        item.id === useInfo.id ? useInfo : item
                    );
                } else {
                    return [...prevDataSource, useInfo];
                }
            });
        }
    }, [useInfo]);

    useEffect(() => {
        if (isSuccessUpdate) {
            notification.success({
                message:
                    (
                        <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                            Sửa thông tin người dùng thành công!
                        </div>
                    )
            })
            refetch()
        }
    }, [isSuccessUpdate])

    return (
        <div className='Onboarding-user-company' style={{ height: '100%' }}>
            <Spin spinning={isLoadGet || isLoadingUpdate}>
                <Content style={{ height: '100%', margin: 20 }} >
                    <Card type="inner" title="Thông tin người dùng quản trị" style={{ height: "100%", width: '100%' }} >
                        <Table columns={columns} scroll={{ x: 2500 }} dataSource={dataSource}></Table>
                    </Card>
                </Content>
                <Footer
                    style={{
                        background: '#fff',
                        // position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        boxShadow: '0px -1px 6px 0px rgba(45, 50, 57, 0.06)',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'inline-block' }}>
                        <Button style={{ margin: '0 8px', height: 40 }} onClick={onGoPrev}>
                            {t(translations.action.previous)}
                        </Button>
                    </div>
                    <div style={{ display: 'inline-block' }}>

                        <Button type="primary" style={{ margin: '0 8px', height: 40 }} onClick={onGoNext}>
                            {t(translations.action.next)}
                        </Button>
                    </div>
                </Footer>
                <Modal
                    title={"Chỉnh sửa người dùng"}
                    open={openModalEdit}
                    onCancel={() => {
                        setOpenModalEdit(false)
                        form.resetFields()
                    }}
                    onOk={() => {
                        // dispatch(saveDraftCompanyEntity({ ...companyEntity, supperAdmin: form.getFieldsValue() }));
                        let userInfo = {
                            ...dataModal,
                            ...form.getFieldsValue(),
                            dateRange: dateString,
                            docType: form.getFieldValue("docType") ? form.getFieldValue("docType") : "CMND"
                        }
                        form.validateFields().then(values => {
                            // dispatch(saveDraftCompanyEntity(...companyEntity, ...{supperAdmin, userInfo}))
                            updateUserInfo(userInfo)
                            // dispatch(updateSuperAdmin(userInfo))
                            setOpenModalEdit(false)
                        }).catch(
                            errorInfo => {
                                notification.error({
                                    message:
                                        (
                                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                                Vui lòng nhập đầy đủ trường của admin để tiếp tục
                                            </div>
                                        )
                                })
                            }
                        )
                    }}
                    footer={() => (
                        <Footer style={{ backgroundColor: "white", padding: 0 }}>
                            <Row justify={'space-between'}>
                                <Col span={3}>
                                    <Button style={{ width: '100%', height: 40 }} onClick={() => {
                                        setOpenModalEdit(false)
                                        form.resetFields()
                                    }}>Hủy</Button>
                                </Col>
                                <Col span={3}>
                                    <Button style={{ width: '100%', height: 40 }} type='primary' onClick={() => {
                                        let userInfo = {
                                            ...dataModal,
                                            ...form.getFieldsValue(),
                                            dateRange: dateString,
                                            docType: form.getFieldValue("docType") ? form.getFieldValue("docType") : "CMND"
                                        }
                                        form.validateFields().then(values => {
                                            updateUserInfo(userInfo)
                                            // dispatch(updateSuperAdmin(userInfo))
                                            setOpenModalEdit(false)
                                        }).catch(
                                            errorInfo => {
                                                notification.error({
                                                    message:
                                                        (
                                                            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                                                                Vui lòng nhập đầy đủ trường của admin để tiếp tục
                                                            </div>
                                                        )
                                                })
                                            }
                                        )
                                    }}>Lưu</Button>
                                </Col>
                            </Row>
                        </Footer>
                    )}
                    width={'70%'}
                    okText="Đồng ý"
                    cancelText="Hủy"
                >
                    <Card>
                        <Form
                            form={form}
                            labelCol={{ lg: { span: 6 }, md: { span: 9 }, sm: { span: 24 }, xs: { span: 24 } }}
                            wrapperCol={{ lg: { span: 10 }, md: { span: 15 }, sm: { span: 20 }, xs: { span: 24 } }}
                            layout="horizontal"
                            // disabled={true}
                            style={{ marginBottom: '5vw' }}
                            initialValues={dataModal}
                            colon={false}
                            requiredMark={false}
                        // onFieldsChange={onFieldsChange}
                        >
                            <Form.Item
                                name="fullName"
                                label={
                                    <span>
                                        Tên hiển thị
                                        <span style={{ color: 'red' }}> *</span>
                                    </span>
                                }
                                labelAlign="left"
                                rules={[{ required: true, message: 'Vui lòng thêm tên tên người dùng!' },
                                {
                                    validator: (_, value) => {
                                        if (value && value.trim().length === 0) {
                                            return Promise.reject(new Error('Tên hiển thị không được chỉ chứa khoảng trắng.'));
                                        }
                                        return Promise.resolve();
                                    }
                                }
                                ]}
                            >
                                <Input style={{ height: 40 }} maxLength={50} />
                            </Form.Item>
                            <Form.Item
                                name={'position'}
                                label={
                                    <span>
                                        Chức vụ
                                        <span style={{ color: 'red' }}> *</span>
                                    </span>
                                }
                                labelAlign="left"
                                rules={[{ required: true, message: 'Vui lòng thêm chức vụ!' }]}
                            >
                                <Select defaultValue={''}>
                                    <Option value=""> </Option>
                                    <Option value="STAFF">Nhân viên</Option>
                                    <Option value="MANAGER">Trưởng phòng</Option>
                                    <Option value="SUB_MANAGER">Phó phòng</Option>
                                    <Option value="LEGAL_REPRESENTATIVE">Người đại diện pháp luật</Option>
                                    <Option value="CHIEF_ACCOUNTANT">Kế toán trưởng</Option>
                                    <Option value="AUTHORIZATION_OF_CHIEF_ACCOUNTANT">Ủy quyền người đại diện pháp luật</Option>
                                    <Option value="AUTHORIZATION_OF_LEGAL_REPRESENTATIVE">Ủy quyền kế toán trưởng</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label=
                                {
                                    <span>
                                        Nhóm người dùng
                                        <span style={{ color: 'red' }}> *</span>
                                    </span>
                                }

                                labelAlign="left"
                                initialValue="Admin"
                            >
                                <Input disabled style={{ height: 40 }} value={"ADMIN"} />
                            </Form.Item>

                            <Form.Item
                                label=
                                {
                                    <span>
                                        Phân quyền ứng dụng
                                        <span style={{ color: 'red' }}> *</span>
                                    </span>
                                }
                                labelAlign="left"
                                initialValue="Admin"
                            >
                                <Input disabled style={{ height: 40 }} value={"ADMIN"} />
                            </Form.Item>

                            <Form.Item label={"Giấy tờ định danh"} name={'docType'} labelAlign="left">
                                <Radio.Group defaultValue={"CMND"} onChange={() => {
                                    form.setFieldValue('identificationNum', '')
                                }}>
                                    <Radio value={"CMND"} >CMND/CCCD/CCCD gắn chíp</Radio>
                                    <Radio value={"HC"}>Hộ chiếu</Radio>
                                </Radio.Group>
                            </Form.Item>


                            <Form.Item
                                name="identificationNum"
                                label={"Số giấy tờ định danh"}
                                labelAlign="left"
                                rules={[
                                    { required: true, message: "Hãy nhập số giấy tờ định danh" },
                                    {
                                        validator: (_, value) => {
                                            if (!value || /^[0-9-\s]+$/.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Số giấy tờ định danh không hợp lệ!'));
                                        },
                                    },
                                    {
                                        validator: (_, value) => {
                                            if (value && value.trim().length === 0) {
                                                return Promise.reject(new Error('Số giấy tờ định danh không được chỉ chứa khoảng trắng.'));
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}

                            >
                                <Input placeholder="Nhập số giấy tờ định danh" style={{ height: 40 }} maxLength={12} />
                            </Form.Item>

                            <Form.Item
                                name={"dateRange"}
                                label={
                                    <span>
                                        Ngày cấp
                                        <span style={{ color: 'red' }}> *</span>
                                    </span>
                                }
                                rules={[{ required: true, message: 'Vui lòng thêm ngày cấp!' },
                                {
                                    validator: (_, value) => {
                                        if (value && value.toDate() > new Date()) {
                                            return Promise.reject('Ngày cấp không được lớn hơn ngày hiện tại!');
                                        }
                                        return Promise.resolve();
                                    },
                                },

                                ]}
                                labelAlign="left"
                            >
                                <DatePicker placeholder='yyyy-mm-dd' format={"DD/MM/YYYY"} style={{ height: 40 }} onChange={onChangeDate} />
                            </Form.Item>

                            <Form.Item name={'issuedBy'} label={
                                <span>
                                    Nơi cấp
                                    <span style={{ color: 'red' }}> *</span>
                                </span>
                            } labelAlign="left" rules={[
                                { required: true, message: 'Hãy nhập nơi cấp!' },
                                {
                                    validator: (_, value) => {
                                        if (value && value.trim().length === 0) {
                                            return Promise.reject(new Error('Nơi cấp không được chỉ chứa khoảng trắng.'));
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            ]}>
                                <Input placeholder="Nhập nơi cấp" style={{ height: 40 }} maxLength={300} />
                            </Form.Item>

                            <Form.Item name={'phoneNumber'}
                                label={<span>
                                    Số điện thoại
                                    <span style={{ color: 'red' }}> *</span>
                                </span>}
                                labelAlign="left"
                                rules={[
                                    { required: true, message: 'Hãy nhập số điện thoại!' },
                                    {
                                        validator: (_, value) => {
                                            if (!value || /^[0-9-\s]+$/.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Số điện thoại không hợp lệ!'));
                                        },
                                    },
                                    {
                                        validator: (_, value) => {
                                            if (value && value.trim().length === 0) {
                                                return Promise.reject(new Error('Số điện thoại không được chỉ chứa khoảng trắng.'));
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}>
                                <Input placeholder="Nhập số điện thoại" style={{ height: 40 }} maxLength={10} />
                            </Form.Item>

                            <Form.Item name={'email'}
                                label={
                                    <span>
                                        {t(translations.company.email)}
                                        <span style={{ color: 'red' }}> *</span>
                                    </span>
                                }
                                labelAlign="left" rules={[{ required: true, message: 'Hãy nhập email!' },
                                {
                                    validator: (_, value) => {
                                        if (value && value.trim().length === 0) {
                                            return Promise.reject(new Error('Email không được chỉ chứa khoảng trắng.'));
                                        }
                                        return Promise.resolve();
                                    }
                                }
                                ]}>
                                <Input disabled placeholder="Nhập email" style={{ height: 40 }} value={useInfo?.email || ""} maxLength={50} />
                            </Form.Item>
                        </Form>
                    </Card>
                </Modal>
            </Spin>
        </div>


    )
}