import './index.css';
import React, { useEffect, useState } from 'react';
import {
  Image,
  Form,
  Input,
  Upload,
  Button,
  DatePicker,
  Radio,
  Col,
  Row,
  Typography,
  RadioChangeEvent,
  Card,
  Divider,
  UploadProps,
  Spin,
  notification,
  Modal,
} from 'antd';
import { Content, Footer } from 'antd/es/layout/layout';
import {
  goNext,
  goPrev,
  saveDraftCompanyEntity,
  updateChiefAccountantBackImage,
  updateChiefAccountantFaceImage,
  updateChiefAccountantFrontImage

} from '../../onboarding.reducer';
import { useAppDispatch, useAppSelector } from '@/config/store';
import { Company } from '@/shared/model/company.model';
import { translations } from '@/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { deepMerge } from '@/shared/utils/object-utils';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupValidator } from '@/shared/utils/yup-utils';
import { useExecuteOcrPersonEKycRepresentativeMutation, useUploadRepresentativeBackImageMutation, useUploadRepresentativeFaceImageMutation, useUploadRepresentativeFrontImageMutation, useVertifyIdentifyMutation } from '@/services/api/onboarding.api';
import dayjs from "dayjs";
import { CloudDownloadOutlined } from '@ant-design/icons';
import { CorporatePositionEnum, EDoctype } from '@/shared/model/enums/enums';
import { fileApi } from '@/services/api/file.api';
const { Title, Text } = Typography;

const getDefaultImage = () => {
  return '/images/default_image.png';
};

function isValidDate(dateString: any): boolean {
  const now = new Date();
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!dateString) {
    return true
  }

  if (!dateRegex.test(dateString)) return false;

  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  if (date > now) {
    return false;
  }

  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

const formatDate = (dateString: string): string => {
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString) || !dateString) {
    return dateString;
  }

  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};
const reFormatDate = (dateString: string): string => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString) || !dateString) {
    return dateString;
  }

  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
};


export default function OnboardingChiefAccountant(props: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  const companyEntity: Company = useAppSelector(state => state.onboarding.entity);
  const { chiefAccountant } = companyEntity;
  const [form] = Form.useForm();

  const [downloadFileFrontImage, { data: frontImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [downloadFileBackImage, { data: backImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()
  const [downloadFileFaceImage, { data: faceImageFile }] = fileApi.endpoints.downloadFile.useLazyQuery()

  const [uploadRepresentativeFrontImage, { reset: resetFrontImage, data: frontImage, isSuccess: isSuccessFrontImage }] = useUploadRepresentativeFrontImageMutation()
  const [uploadRepresentativeBackImage, { reset: resetBackImage, data: backImage, isSuccess: isSuccessBackImage }] = useUploadRepresentativeBackImageMutation()
  const [uploadRepresentativeFaceImage, { reset: resetFaceImage, data: faceImage, isSuccess: isSuccessFaceImage }] = useUploadRepresentativeFaceImageMutation()
  const [executeOcrPersonEKycRepresentative, { data: dataOCR, isLoading: isLoadingOCR }] = useExecuteOcrPersonEKycRepresentativeMutation();
  const [vertifyIdentify, { data: vertifyIdentifyUser, isLoading: isLoadingVertify, isSuccess: isSuccessVertify, isError: isErrorVertify }] = useVertifyIdentifyMutation();
  const [count, setCount] = useState(true);
  const [docType, setDocType] = useState(companyEntity?.chiefAccountant?.docType || "CMND");
  const [confirmDeleteFile, setConfirmDeleteFile] = useState(false);
  const [typeFileDelete, setTypeFileDelete] = useState('');

  const [extract, setExtract] = useState(false);
  const [changeDocType, setChangeDocType] = useState(false)
  const [localFrontImage, setLocalFrontImage] = useState<any>(null)
  const [localBackImage, setLocalBackImage] = useState<any>(null)
  const [localFaceImage, setLocalFaceImage] = useState<any>(null)

  let schema = yup.object().shape({
    chiefAccountant: yup.object().shape({
      name: yup.string().nullable().test('valid', 'Họ và tên không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      cardIssueDate: yup.string().nullable()
        .test('valid-date', 'Ngày cấp không hợp lệ.', value => {
          if (!value) return true;
          if (value.trim().length === 0) return false;
          if (dayjs(value).isAfter(dayjs())) return false;
          return true;
        }),
      birthday: yup.string().nullable()
        .test('valid-date', 'Ngày sinh không hợp lệ.', value => {
          if (!value) return true;
          if (value.trim().length === 0) return false;
          if (dayjs(value).isAfter(dayjs())) return false;
          return true;
        }),
      phoneNumber: yup.string().nullable().test('valid', 'Số điện thoại không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      cardID: yup.string().nullable().test('valid', 'Số giấy tờ định danh không được chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      cardIssueLocation: yup.string().nullable().test('valid', 'Nơi cấp không được  chỉ chứa khoảng trắng', value => {
        if (!value) return true;
        if (value.trim().length === 0) return false;
        return true;
      }),
      email: yup.string().email(t(translations.register.errors.emailInvalid)).nullable()
        .test('valid email', t(translations.register.errors.emailInvalid), (value: any) => {
          if (!value) return true
          if (value.trim().length === 0) return false;
          return /^(?=.{6,30}$)(?!.*\.\.)(?!.*\.$)(?!^\.)[a-zA-Z0-9.]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/.test(value)
        }),
      cardFrontImage: yup.string().nullable(),
      cardBackImage: yup.string().nullable(),
      faceImage: yup.string().nullable(),
    }),
  });

  const yupSync = yupValidator(schema, form.getFieldsValue);

  interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
  }

  const uploadFrontImageProps: UploadProps = {
    // // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();

      formData.append('file', file);
      uploadRepresentativeFrontImage(formData)
      return false;
    },
  };
  const uploadBackImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();
      formData.append('file', file);
      uploadRepresentativeBackImage(formData)
      return false;
    },
  };
  const uploadFaceImageProps: UploadProps = {
    // onRemove: file => { },
    beforeUpload: file => {
      const isImage = validImageTypes.includes(file.type);
      const maxSizeMB = 5;

      if (!isImage) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`tên file ${file.name} không hợp lệ`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }

      if (file.size / 1024 / 1024 > maxSizeMB) {
        notification.error({
          message:
            (
              <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                {`file ảnh chỉ chứa tối đa ${maxSizeMB}MB`}
              </div>
            )
        })
        return Upload.LIST_IGNORE;
      }
      const formData = new FormData();
      formData.append('file', file);
      uploadRepresentativeFaceImage(formData)
      return false;
    },
  };

  const onGoNext = () => {
    let uploadFile = false

    if (companyEntity.chiefAccountant?.cardFrontImage ||
      companyEntity.chiefAccountant?.cardBackImage ||
      companyEntity.chiefAccountant?.faceImage) {
      if (!companyEntity.chiefAccountant?.cardFrontImage) {
        form.setFields([{
          name: ['chiefAccountant', 'cardFrontImage'],
          errors: ["Vui lòng tải đầy đủ ảnh!"]
        }])
        uploadFile = true
      }
      if (!companyEntity.chiefAccountant?.cardBackImage && companyEntity.chiefAccountant.docType === "CMND") {
        form.setFields([{
          name: ['chiefAccountant', 'cardBackImage'],
          errors: ["Vui lòng tải đầy đủ ảnh!"]
        }])
        uploadFile = true
      }
      if (!companyEntity.chiefAccountant?.faceImage) {
        form.setFields([{
          name: ['chiefAccountant', 'faceImage'],
          errors: ["Vui lòng tải đầy đủ ảnh!"]
        }])
        uploadFile = true
      }
    }

    if (!uploadFile) {
      form
        .validateFields()
        .then(async values => {
          if (companyEntity?.chiefAccountant?.docType === EDoctype.CCCD && companyEntity?.chiefAccountant?.cardFrontImage && companyEntity?.chiefAccountant?.cardBackImage && companyEntity?.chiefAccountant?.faceImage && !extract
            || companyEntity?.chiefAccountant?.docType === EDoctype.HC && companyEntity?.chiefAccountant?.cardFrontImage && companyEntity?.chiefAccountant?.faceImage && !extract
          ) {
            notification.info({
              message:
                (
                  <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
                    Hãy trích xuất hình ảnh để tiếp tục!
                  </div>
                )
            })
          }

          else {
            let date = form.getFieldValue(['chiefAccountant', 'cardIssueDate'])
            let birthDay = form.getFieldValue(['chiefAccountant', 'birthday'])

            let entityForm = form.getFieldsValue();

            entityForm = {
              ...entityForm,
              chiefAccountant: {
                ...entityForm.chiefAccountant,
                position: CorporatePositionEnum.ACCOUNTANT,
                cardIssueDate: date ? date.format("YYYY-MM-DD") : "",
                birthday: birthDay ? birthDay.format("YYYY-MM-DD") : ""
              }
            }

            let chiefAccountantOriginal = deepMerge(companyEntity.chiefAccountant, entityForm.chiefAccountant)
            dispatch(saveDraftCompanyEntity({ ...companyEntity, chiefAccountant: chiefAccountantOriginal }));
            dispatch(goNext({ ...companyEntity, chiefAccountant: chiefAccountantOriginal }));

          }

        })
        .catch(errorInfo => {console.log(errorInfo)});
    }
  };
  const onGoPrev = () => {
    let date = form.getFieldValue(['chiefAccountant', 'cardIssueDate'])
    let birthDay = form.getFieldValue(['chiefAccountant', 'birthday'])
    let entityForm = form.getFieldsValue();

    entityForm = {
      ...entityForm,
      chiefAccountant: {
        ...entityForm.chiefAccountant,
        cardIssueDate: date ? date.format("YYYY-MM-DD") : null,
        birthday: birthDay ? birthDay.format("YYYY-MM-DD") : null
      }
    }

    let chiefAccountantOriginal = deepMerge(companyEntity.chiefAccountant, entityForm.chiefAccountant)
    dispatch(saveDraftCompanyEntity({
      ...companyEntity,
      chiefAccountant: {
        ...companyEntity.chiefAccountant,
        ...chiefAccountantOriginal
      }
    }));
    dispatch(goPrev({
      ...companyEntity,
      chiefAccountant: {
        ...companyEntity.chiefAccountant,
        ...chiefAccountantOriginal
      }
    }));
  };

  const onChangeDocType = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    setDocType(newValue);
    form.setFieldsValue({
      chiefAccountant: {
        ...form.getFieldValue('chiefAccountant'),
        docType: newValue,
      },
    });
    setChangeDocType(true)
  };

  const onGetOcrPersonEKyc = async () => {
    await vertifyIdentify({
      frontImage: companyEntity?.chiefAccountant?.cardFrontImage,
      faceImage: companyEntity?.chiefAccountant?.faceImage,
    });
  };

  const deleteFile = (values: any) => {
    let dataDeleteFile
    if (values === "front") {
      setLocalFrontImage(null)
      resetFrontImage()
      setTypeFileDelete("front")
      let deleteFile = {
        ...companyEntity.chiefAccountant,
        cardFrontImage: null,
      }
      dataDeleteFile = deepMerge(companyEntity?.chiefAccountant, deleteFile)

      form.setFieldValue(['chiefAccountant', 'cardFrontImage'], null)
    }

    else if (values === "back") {
      setLocalBackImage(null)
      resetBackImage()
      setTypeFileDelete("back")
      let deleteFile = {
        ...companyEntity.chiefAccountant,
        cardBackImage: null,
      }
      dataDeleteFile = deepMerge(companyEntity?.chiefAccountant, deleteFile)

      form.setFieldValue(['chiefAccountant', 'cardBackImage'], null)

    }

    else if (values === "face") {
      setLocalFaceImage(null)
      resetFaceImage()
      setTypeFileDelete("face")
      let deleteFile = {
        ...companyEntity.chiefAccountant,
        faceImage: null
      }
      dataDeleteFile = deepMerge(companyEntity?.chiefAccountant, deleteFile)

      form.setFieldValue(['chiefAccountant', 'faceImage'], null)

    }

    setExtract(false)

    const fieldsToReset = [
      ['chiefAccountant', 'email'], ['chiefAccountant', 'phoneNumber'], ['chiefAccountant', 'birthday'], ['chiefAccountant', 'cardIssueLocation'], ['chiefAccountant', 'cardIssueDate'], ['chiefAccountant', 'cardID'], ['chiefAccountant', 'name']
    ];

    fieldsToReset.forEach(field => form.setFieldValue(field, null));

    dispatch(saveDraftCompanyEntity({
      ...companyEntity,
      chiefAccountant: {
        ...chiefAccountant,
        ...dataDeleteFile,
        docType: docType
      }
    }));
  }

  useEffect(() => {
    if (chiefAccountant?.cardFrontImage) {
      form.setFieldValue(['chiefAccountant', 'cardFrontImage'], chiefAccountant?.cardFrontImage)
    }
    if (chiefAccountant?.cardBackImage) {
      form.setFieldValue(['chiefAccountant', 'cardBackImage'], chiefAccountant?.cardBackImage)
    }
    if (chiefAccountant?.faceImage) {
      form.setFieldValue(['chiefAccountant', 'faceImage'], chiefAccountant?.faceImage)
    }
  }, [chiefAccountant])

  useEffect(() => {
    if (companyEntity.chiefAccountant?.cardFrontImage) {
      form.setFields([{
        name: ['chiefAccountant', 'cardFrontImage'],
        errors: []
      }])
    }
    if (companyEntity.chiefAccountant?.cardBackImage) {
      form.setFields([{
        name: ['chiefAccountant', 'cardBackImage'],
        errors: []
      }])
    }
    if (companyEntity.chiefAccountant?.faceImage) {
      form.setFields([{
        name: ['chiefAccountant', 'faceImage'],
        errors: []
      }])
    }
  }, [companyEntity.chiefAccountant])



  useEffect(() => {
    if (changeDocType) {
      setLocalFrontImage(null)
      setLocalBackImage(null)
      setLocalFaceImage(null)
      resetFrontImage()
      resetBackImage()
      resetFaceImage()

      let deleteFile = {
        ...companyEntity.chiefAccountant,
        cardFrontImage: null,
        cardBackImage: null,
        faceImage: null
      }
      let dataDeleteFile = deepMerge(companyEntity?.chiefAccountant, deleteFile)

      dispatch(saveDraftCompanyEntity({
        ...companyEntity,
        chiefAccountant: {
          ...chiefAccountant,
          ...dataDeleteFile,
          docType: docType
        }
      }));

      form.resetFields();

      const fieldsToReset = [
        ['chiefAccountant', 'email'], ['chiefAccountant', 'phoneNumber'], ['chiefAccountant', 'birthday'], ['chiefAccountant', 'cardIssueLocation'], ['chiefAccountant', 'cardIssueDate'], ['chiefAccountant', 'cardID'], ['chiefAccountant', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));

      form.setFieldsValue({
        chiefAccountant: {
          docType: docType,
          position: "Kế toán trưởng",
        },
      });

      setExtract(false)

      setChangeDocType(false)
    }
  }, [docType])

  useEffect(() => {
    if (frontImageFile) {
      setLocalFrontImage(frontImageFile);
    }
  }, [frontImageFile])

  useEffect(() => {
    if (backImageFile) setLocalBackImage(backImageFile);

  }, [backImageFile])

  useEffect(() => {
    if (faceImageFile) setLocalFaceImage(faceImageFile);

  }, [faceImageFile]);

  useEffect(() => {
    setCount(false)
    if (isSuccessVertify && vertifyIdentifyUser && !count) {
      executeOcrPersonEKycRepresentative(companyEntity.chiefAccountant || {});
    }

    if (isSuccessVertify && !vertifyIdentifyUser && !count) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              eKYC cá nhân không khớp với ảnh trên giấy tờ
            </div>
          )
      })
      setExtract(false)
    }

    if (isErrorVertify && !count) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Không thể thực hiện nhận dạng!
            </div>
          )
      })
      setExtract(false)
    }
    // }

  }, [vertifyIdentifyUser, isLoadingVertify, isSuccessVertify, isErrorVertify])

  useEffect(() => {
    dispatch(saveDraftCompanyEntity({ ...companyEntity, ...{ hasRegisterGuarantee: true, hasRegisterLC: true, hasRegisterSCF: true, } }));
  }, [])

  useEffect(() => {
    if (chiefAccountant?.cardFrontImage) {
      downloadFileFrontImage({ fileId: chiefAccountant.cardFrontImage, objectType: null })
    }
  }, [chiefAccountant?.cardFrontImage]);
  useEffect(() => {
    if (chiefAccountant?.cardBackImage) {
      downloadFileBackImage({ fileId: chiefAccountant.cardBackImage, objectType: null })
    }
  }, [chiefAccountant?.cardBackImage]);
  useEffect(() => {
    if (chiefAccountant?.faceImage) {
      downloadFileFaceImage({ fileId: chiefAccountant.faceImage, objectType: null })
    }
  }, [chiefAccountant?.faceImage]);

  useEffect(() => {
    if (isSuccessFrontImage && !!frontImage?.fileRef) {
      //update FrontImage
      setExtract(false)

      const fieldsToReset = [
        ['chiefAccountant', 'email'], ['chiefAccountant', 'phoneNumber'], ['chiefAccountant', 'birthday'], ['chiefAccountant', 'cardIssueLocation'], ['chiefAccountant', 'cardIssueDate'], ['chiefAccountant', 'cardID'], ['chiefAccountant', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      dispatch(updateChiefAccountantFrontImage(frontImage))
    }
  }, [isSuccessFrontImage])
  useEffect(() => {
    if (isSuccessBackImage && !!backImage?.fileRef) {
      setExtract(false)

      const fieldsToReset = [
        ['chiefAccountant', 'email'], ['chiefAccountant', 'phoneNumber'], ['chiefAccountant', 'birthday'], ['chiefAccountant', 'cardIssueLocation'], ['chiefAccountant', 'cardIssueDate'], ['chiefAccountant', 'cardID'], ['chiefAccountant', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      dispatch(updateChiefAccountantBackImage(backImage))
    }
  }, [isSuccessBackImage])
  useEffect(() => {
    if (isSuccessFaceImage && !!faceImage?.fileRef) {
      //update FaceImage
      setExtract(false)

      const fieldsToReset = [
        ['chiefAccountant', 'email'], ['chiefAccountant', 'phoneNumber'], ['chiefAccountant', 'birthday'], ['chiefAccountant', 'cardIssueLocation'], ['chiefAccountant', 'cardIssueDate'], ['chiefAccountant', 'cardID'], ['chiefAccountant', 'name']
      ];

      fieldsToReset.forEach(field => form.setFieldValue(field, null));
      dispatch(updateChiefAccountantFaceImage(faceImage))
    }
  }, [isSuccessFaceImage])

  useEffect(() => {
    if (dataOCR && dataOCR.cardIssueDate) {
      let dataOcrNew

      if (dataOCR.docType === null) {
        dataOcrNew = {
          ...dataOCR,
          docType: docType
        }
      }
      else {
        dataOcrNew = dataOCR
      }
      dispatch(saveDraftCompanyEntity({ ...companyEntity, chiefAccountant: dataOcrNew }))
      form.setFieldsValue({
        chiefAccountant: {
          ...dataOcrNew,
          position: "Kế toán trưởng",
          birthday: dataOcrNew?.birthday ? dayjs(dataOcrNew?.birthday, "YYYY-MM-DD") : "",
          cardIssueDate: dataOcrNew?.cardIssueDate ? dayjs(dataOcrNew?.cardIssueDate, "YYYY-MM-DD") : ""
        }
      });

      notification.success({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Trích xuất thông tin thành công!
            </div>
          )
      });
      setExtract(true);

    }

    if (dataOCR === null || (dataOCR?.name === null && dataOCR?.cardIssueDate === null)) {
      notification.error({
        message:
          (
            <div style={{ fontWeight: 600, fontFamily: 'Lexend' }}>
              Trích xuất thông tin không thành công!
            </div>
          )
      });
      setExtract(false)
    }
  }, [dataOCR])


  useEffect(() => {
    let entity = deepMerge(companyEntity)
    entity = {
      ...entity,
      chiefAccountant: {
        ...entity.chiefAccountant,
        birthday: entity.chiefAccountant.birthday ? dayjs(entity.chiefAccountant.birthday, "YYYY-MM-DD") : "",
        cardIssueDate: entity.chiefAccountant.cardIssueDate ? dayjs(entity.chiefAccountant.cardIssueDate) : ""
      }
    }
    form.setFieldsValue(entity)

    form.setFieldsValue({
      chiefAccountant: {
        position: "Kế toán trưởng",
      },
    });

    if (!companyEntity.chiefAccountant?.docType) {
      dispatch(saveDraftCompanyEntity({
        ...companyEntity,
        chiefAccountant: {
          ...chiefAccountant,
          docType: "CMND"
        }
      }));
      form.setFieldsValue({
        chiefAccountant: {
          docType: "CMND",
        },
      });
    }

    if (chiefAccountant?.cardFrontImage) downloadFileFrontImage({ fileId: chiefAccountant.cardFrontImage, objectType: null })
    if (chiefAccountant?.cardBackImage) downloadFileBackImage({ fileId: chiefAccountant.cardBackImage, objectType: null })
    if (chiefAccountant?.faceImage) downloadFileFaceImage({ fileId: chiefAccountant.faceImage, objectType: null })

    if (
      (chiefAccountant?.docType?.toString() === "CMND" && chiefAccountant?.cardBackImage !== null && chiefAccountant?.cardFrontImage != null && chiefAccountant?.faceImage !== null && chiefAccountant?.name !== null && chiefAccountant?.cardIssueDate !== null) ||
      (chiefAccountant?.docType?.toString() === "HC" && chiefAccountant?.cardFrontImage != null && chiefAccountant?.faceImage !== null && chiefAccountant?.name !== null && chiefAccountant?.cardIssueDate !== null)
    ) {
      setExtract(true)
    }
  }, [])

  return (
    <Spin spinning={isLoadingOCR || isLoadingVertify}>
      <Content className='chiefAccount'>
        <Card type='inner' title='Thông tin người đại diện' style={{ margin: 20 }}>
          <Row>
            <Form colon={false} form={form} initialValues={{ chiefAccountant: { docType } }}>
              <Form.Item label={"Loại giấy tờ"} name={['chiefAccountant', 'docType']}>
                <Radio.Group onChange={onChangeDocType} value={companyEntity?.chiefAccountant?.docType?.toString()} defaultValue={'CMND'}>
                  <Radio value={"CMND"} >CMND/CCCD/CCCD gắn chíp</Radio>
                  <Radio value={"HC"}>Hộ chiếu</Radio>
                </Radio.Group>
              </Form.Item>
              <Divider orientation="right" />
            </Form>
          </Row>
          <Form colon={false} id='orcForm'
            form={form}
          >
            {/* <Form.Item name={['chiefAccountant', 'hasEKYC']} >
            <Input />
          </Form.Item> */}

          </Form>
          <Form id='imagesForm'
            form={form}
          >
            <Row style={{ margin: '24px 0px' }}>
              <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }} style={{}}>
                <div
                  style={{
                    padding: '12px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px 8px 8px 8px',
                    minHeight: 200,
                    marginRight: 10
                  }}
                >
                  <Form.Item
                    name={['chiefAccountant', 'cardFrontImage']}

                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}

                  >
                    <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                      <Image
                        style={{ width: '100%', maxHeight: '18vw' }}
                        preview={false}
                        src={
                          localFrontImage || getDefaultImage()
                        }
                        fallback={getDefaultImage()}
                      />
                    </div>
                  </Form.Item>

                  <h5>Ảnh mặt trước giấy tờ</h5>
                  <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                  <Row justify={'space-between'}>
                    <Col lg={{ span: 11 }}>
                      <Upload {...uploadFrontImageProps} maxCount={1} showUploadList={false}>
                        <Button disabled={isLoadingOCR || isLoadingVertify}
                          style={{
                            width: '100%',
                            border: 'none',
                            backgroundColor: 'rgba(232, 241, 254, 1)',
                            color: 'rgba(24, 119, 242, 1)',
                            height: 40
                          }}
                        >
                          Tải ảnh lên
                        </Button>
                      </Upload>
                    </Col>
                    <Col lg={{ span: 11 }}>
                      <Button disabled={!chiefAccountant?.cardFrontImage} onClick={() => {
                        setConfirmDeleteFile(true)
                        setTypeFileDelete("front")
                      }
                      } style={{ width: '100%', height: 40 }}>
                        Xóa
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>

              {(docType !== "HC") && (
                <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                  <div
                    style={{
                      padding: '12px',
                      border: '1px solid #f0f0f0',
                      borderRadius: '8px 8px 8px 8px',
                      minHeight: 200,
                      marginRight: 10
                    }}
                  >
                    <Form.Item
                      name={['chiefAccountant', 'cardBackImage']}

                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}

                    >
                      <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                        <Image
                          style={{ width: '100%', maxHeight: '18vw' }}
                          preview={false}
                          src={
                            localBackImage || getDefaultImage()
                          }
                          fallback={getDefaultImage()}
                        />
                      </div>
                    </Form.Item>

                    <h5>Ảnh mặt sau giấy tờ</h5>
                    <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                    <Row justify={'space-between'}>
                      <Col lg={{ span: 11 }}>
                        <Upload {...uploadBackImageProps} maxCount={1} showUploadList={false}>
                          <Button disabled={isLoadingOCR || isLoadingVertify}
                            style={{
                              width: '100%',
                              border: 'none',
                              backgroundColor: 'rgba(232, 241, 254, 1)',
                              color: 'rgba(24, 119, 242, 1)',
                              height: 40
                            }}
                          >
                            Tải ảnh lên
                          </Button>
                        </Upload>
                      </Col>
                      <Col lg={{ span: 11 }}>
                        <Button disabled={!chiefAccountant?.cardBackImage} onClick={() => {
                          setConfirmDeleteFile(true)
                          setTypeFileDelete("back")
                        }} style={{ width: '100%', height: 40 }}>
                          Xóa
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}

              <Col lg={{ span: 8 }} md={{ span: 24 }} xs={{ span: 24 }}>
                <div
                  style={{
                    padding: '12px',
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px 8px 8px 8px',
                    minHeight: 200
                  }}
                >
                  <Form.Item
                    name={['chiefAccountant', 'faceImage']}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ width: '23vw', height: '18vw', display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 200, minHeight: 90 }}>
                      <Image
                        style={{ width: '100%', maxHeight: '18vw' }}
                        preview={false}
                        src={
                          localFaceImage || getDefaultImage()
                        }
                        fallback={getDefaultImage()}
                      />
                    </div>
                  </Form.Item>
                  <h5>eKYC cá nhân</h5>
                  <p>Định dạng JPG, PNG, dung lượng không quá 5MB</p>
                  <Row justify={'space-between'}>
                    <Col lg={{ span: 11 }}>
                      <Upload {...uploadFaceImageProps} maxCount={1} showUploadList={false}>
                        <Button disabled={isLoadingOCR || isLoadingVertify}
                          style={{
                            width: '100%',
                            border: 'none',
                            backgroundColor: 'rgba(232, 241, 254, 1)',
                            color: 'rgba(24, 119, 242, 1)',
                            height: 40
                          }}
                        >
                          Tải ảnh lên
                        </Button>
                      </Upload>
                    </Col>
                    <Col lg={{ span: 11 }}>
                      <Button disabled={!chiefAccountant?.faceImage} onClick={() => {
                        setConfirmDeleteFile(true)
                        setTypeFileDelete("face")
                      }} style={{ width: '100%', height: 40 }}>
                        Xóa
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>

          {((docType === "CMND" && !!chiefAccountant?.cardFrontImage &&
            !!chiefAccountant?.cardBackImage &&
            !!chiefAccountant?.faceImage) ||
            (docType === "HC" && !!chiefAccountant?.cardFrontImage &&
              !!chiefAccountant?.faceImage)) && (
              <Row>
                <Col lg={{ span: 12 }}>
                  {/* <Row justify={'end'}>
                    {(isLoadingOCR || isLoadingVertify) && <Spin>Đang tải thông tin</Spin>}
                  </Row> */}
                </Col>
                <Col lg={{ span: 12 }}>
                  <Row justify={'end'}>
                    <Button disabled={isLoadingOCR || isLoadingVertify} onClick={onGetOcrPersonEKyc} style={{ backgroundColor: '#1c00c2', color: 'white', height: 40 }}><CloudDownloadOutlined /> Trích xuất</Button>
                  </Row>
                </Col>
              </Row>
            )}
        </Card>


        {/* <Divider /> */}
        <Card type='inner' title='Xác thực thông tin kế toán trưởng' style={{ margin: 20 }}>
          <Row>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Form colon={false}
                form={form}
                labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                layout="horizontal"
                disabled={!extract ? true : false}
                requiredMark={false}
              >
                <Form.Item
                  name={['chiefAccountant', 'name']}
                  // name={'name'}
                  label=
                  {
                    <span>
                      Họ và tên
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Họ và tên không được để trống" }]}
                >
                  <Input placeholder="Nhập họ và tên" style={{ height: 40 }} maxLength={50} />
                </Form.Item>
                <Form.Item
                  name={['chiefAccountant', 'cardID']}
                  // name={'name'}
                  label={
                    <span>
                      Số giấy tờ định danh
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Số giấy tờ định danh không được để trống" }]}
                >
                  <Input placeholder="Nhập số giấy tờ kinh doanh" style={{ height: 40 }} maxLength={12} />
                </Form.Item>

                <Form.Item
                  name={['chiefAccountant', 'cardIssueDate']}
                  label={
                    <span>
                      Ngày cấp
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Ngày cấp không được để trống" }]}
                  style={{ width: '100%' }}
                >
                  <DatePicker placeholder='Ngày cấp' style={{ height: 40, width: "100%" }} format={"DD/MM/YYYY"} />

                  {/* <Input placeholder="Nhập ngày cấp" style={{ height: 40 }} /> */}
                </Form.Item>

                <Form.Item
                  name={['chiefAccountant', 'cardIssueLocation']}
                  // name={'name'}
                  label={
                    <span>
                      Nơi cấp
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"

                  rules={[yupSync, { required: extract ? true : false, message: "Nơi cấp không được để trống" }]}
                >
                  <Input placeholder="Nhập nơi cấp" style={{ height: 40 }} maxLength={300} />
                </Form.Item>
              </Form>
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}

            >
              <Form
                form={form}
                labelCol={{ xxl: { span: 8 }, xl: { span: 10 }, lg: { span: 12 }, sm: { span: 12 }, xs: { span: 24 } }}
                wrapperCol={{ lg: { span: 12 }, sm: { span: 14 } }}
                layout="horizontal"
                disabled={!extract ? true : false}
                requiredMark={false}
                colon={false}
              >
                <Form.Item
                  name={['chiefAccountant', 'birthday']}
                  label="Ngày sinh"
                  labelAlign="left"
                  rules={[yupSync, { required: extract, message: "Ngày sinh không được để trống" }]}
                  style={{ width: '100%' }}
                >
                  <DatePicker placeholder='Ngày sinh' style={{ height: 40, width: "100%" }} format={"DD/MM/YYYY"} />
                  {/* <Input placeholder='Nhập ngày sinh' style={{ height: 40 }} /> */}
                </Form.Item>

                <Form.Item
                  label="Chức vụ"
                  labelAlign="left"
                  style={{ width: '100%' }}
                  name={['chiefAccountant', 'position']}
                >
                  <Input disabled style={{ height: 40 }} />
                </Form.Item>


                <Form.Item
                  name={['chiefAccountant', 'phoneNumber']}
                  label={
                    <span>
                      Số điện thoại
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract, message: "Số điện thoại không được để trống" }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder='Nhập số điện thoại' style={{ height: 40 }} maxLength={10} />
                </Form.Item>

                <Form.Item
                  name={['chiefAccountant', 'email']}
                  label={
                    <span>
                      Email
                      {extract && <span style={{ color: 'red' }}> *</span>}
                    </span>
                  }
                  labelAlign="left"
                  rules={[yupSync, { required: extract, message: "Email không được để trống" }]}
                  style={{ width: '100%' }}
                >
                  <Input placeholder='Nhập email' style={{ height: 40 }} maxLength={50} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Content>

      <Footer
        style={{
          background: '#fff',
          // position: 'fixed',
          bottom: 0,
          width: '100%',
          boxShadow: '0px -1px 6px 0px rgba(45, 50, 57, 0.06)',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'inline-block' }}>
          {/* <Button disabled={isLoadingOCR} style={{ margin: '0 8px', height: 40 }} onClick={onSaveEntity}>
            {t(translations.action.save_draft)}
          </Button> */}
          <Button disabled={isLoadingOCR || isLoadingVertify} style={{ margin: '0 8px', height: 40 }} onClick={onGoPrev}>
            {t(translations.action.previous)}
          </Button>
        </div>
        <div style={{ display: 'inline-block' }}>

          <Button type="primary" style={{ margin: '0 8px', height: 40 }} onClick={onGoNext} disabled={isLoadingOCR || isLoadingVertify}>
            {t(translations.action.next)}
          </Button>
        </div>
      </Footer>

      <Modal
        open={confirmDeleteFile}
        onCancel={() => setConfirmDeleteFile(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <Row gutter={[16, 16]}>
            <Col lg={{ span: 12 }}>
              <Button style={{ width: '100%', height: 40 }} key="console" onClick={() => {
                setConfirmDeleteFile(false)
              }}>
                Hủy
              </Button>
            </Col>
            <Col lg={{ span: 12 }}>
              <Button style={{ width: '100%', height: 40, backgroundColor: '#ED5E68' }} type="primary" key="console" onClick={() => {
                deleteFile(typeFileDelete)
                setConfirmDeleteFile(false)
              }}>
                Xóa
              </Button>
            </Col>
          </Row>
        )}
      >
        {/* <Result
          title="Xác nhận xóa file"
          extra={
            <Row gutter={[16, 16]}>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%' }} key="console" onClick={() => {
                  setConfirmDeleteFile(false)
                }}>
                  Hủy
                </Button>
              </Col>
              <Col lg={{ span: 12 }}>
                <Button style={{ width: '100%' }} type="primary" key="console" onClick={() => {
                  deleteFile(typeFileDelete)
                  setConfirmDeleteFile(false)
                }}>
                  Xác nhận
                </Button>
              </Col>

            </Row>
          }
        /> */}
        <p style={{ margin: '24px 0', textAlign: 'center' }}>Bạn có chắc chắn muốn xóa ảnh</p>
      </Modal>
    </Spin>
  );
}


